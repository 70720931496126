@import "vars";

.header-container {
  &.invert {
    border: none;
    &:before {
      content: "";
      display: block;
      position: absolute;
      background-color: rgba(0, 0, 0, .5);
      height: 100%;
      top: 0;
      left: 0;
      width: 100%;
      line-height: 0;
    }
  }
}

.pegas {
  &-text {

  }
  &-color {
    &-blue {
      color: @blue;
    }
  }
  &-table {
    &-catalog {
      th {
        padding: 10px;
        text-align: left;
        background-color: @lightGrey;
        .fz(16);
        color: #000000;
      }
      td {
        padding-left: 10px;
        text-align: left;
        color: #000000;
        vertical-align: middle;
        line-height: 50px;
      }
    }
    &-img-wrap {
      display: inline-block;
      margin-left: 10px;

      img {
        max-width: 100%;
      }
    }
    &-vendor {
      .pegas-table-img-wrap {
        max-width: 150px;
      }
    }
    &-row-catalog-link {
      span {
        text-transform: uppercase;
        .fz(16);
      }
      .pegas-table-img-wrap {
        width: 50px;
      }
    }
  }

  &-service-page {
    margin-top: 20px;

    .manager-inf {
      h2 {
        .fz(22);
      }
      h3 {
        .fz(20);
      }
      ul > li {
        list-style: none;
        a {

        }
      }
    }
  }

  &-service-page-table {
    margin-top: 20px;
    text-align: center;

    th {
      background-color: @lightGrey;
      padding: 10px 10px 10px 20px;
      text-align: center;
      color: #000000;
    }
    tr {
      &:not(:first-child) {
        .fz(18);
      }
    }

    .pegas-table-img-wrap {
      max-width: 150px;
    }
  }
}

#pegas-capcha {
  border: none;
}

.page-title{
  margin-left: 27px;
}