/*fonts*/

/*colors*/

/*placeholder*/

input[type="text"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #cecece;
}

input[type="text"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="number"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
textarea:-moz-placeholder {
  color: #cecece;
}

input[type="text"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
textarea::-moz-placeholder {
  color: #cecece;
}

input[type="text"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #cecece;
}

/* em */

.header-container.invert {
  border: none;
}

.header-container.invert:before {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  line-height: 0;
}

.pegas-color-blue {
  color: #12b9e8;
}

.pegas-table-catalog th {
  padding: 10px;
  text-align: left;
  background-color: #eee;
  font-size: 1em;
  color: #000000;
}

.pegas-table-catalog td {
  padding-left: 10px;
  text-align: left;
  color: #000000;
  vertical-align: middle;
  line-height: 50px;
}

.pegas-table-img-wrap {
  display: inline-block;
  margin-left: 10px;
}

.pegas-table-img-wrap img {
  max-width: 100%;
}

.pegas-table-vendor .pegas-table-img-wrap {
  max-width: 150px;
}

.pegas-table-row-catalog-link span {
  text-transform: uppercase;
  font-size: 1em;
}

.pegas-table-row-catalog-link .pegas-table-img-wrap {
  width: 50px;
}

.pegas-service-page {
  margin-top: 20px;
}

.pegas-service-page .manager-inf h2 {
  font-size: 1.375em;
}

.pegas-service-page .manager-inf h3 {
  font-size: 1.25em;
}

.pegas-service-page .manager-inf ul > li {
  list-style: none;
}

.pegas-service-page-table {
  margin-top: 20px;
  text-align: center;
}

.pegas-service-page-table th {
  background-color: #eee;
  padding: 10px 10px 10px 20px;
  text-align: center;
  color: #000000;
}

.pegas-service-page-table tr:not(:first-child) {
  font-size: 1.125em;
}

.pegas-service-page-table .pegas-table-img-wrap {
  max-width: 150px;
}

#pegas-capcha {
  border: none;
}

.page-title {
  margin-left: 27px;
}