/*fonts*/
//@import url('https://fonts.googleapis.com/css?family=Lato');
//@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

/*colors*/
@blue: #12b9e8;
@placeholderColor: #cecece;
@lightGrey: #eee;

/*placeholder*/
input[type="text"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder{color:@placeholderColor}
input[type="text"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="number"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
textarea:-moz-placeholder{color:@placeholderColor}
input[type="text"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="number"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
textarea::-moz-placeholder{color:@placeholderColor}
input[type="text"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
textarea:-ms-input-placeholder{color:@placeholderColor}

/* em */
.fz(@value){
  font-size: @value / 16em;
}